import { promotionCnf } from "@/constant/config.js";
import axios from "axios";
const queryString = require("query-string");
export default {
    saveAndSendPromot(request) {
        return axios({
            method: "POST",
            url: `${promotionCnf.saveAndSendPromotUrl}`,
            withCredentials: true,
            data: request,
        });
    },
    savePromotion(request) {
        return axios({
            method: "POST",
            url: `${promotionCnf.savePromotionUrl}`,
            withCredentials: true,
            data: request,
        });
    },
    coppyPromotion(request) {
        return axios({
            method: "POST",
            url: `${promotionCnf.coppyPromotionUrl}`,
            withCredentials: true,
            data: request,
        });
    },

    getElementsListPromotion(pageNumber, requestParam) {
        let queryParams = queryString.stringify(
            Object.assign(
                {
                    queryAction: requestParam.queryAction,
                    querySearch: requestParam.querySearch,
                    pageIndex: pageNumber,
                    pageSize: requestParam.pageSize,
                },
                requestParam.filterItem
            )
        );
        return axios({
            method: "GET",
            url: `${promotionCnf.getAllPromotionUrl}?${queryParams}`,
            withCredentials: true,
        });
    },

    getElementsListCampaign(pageNumber, requestParam) {
        let queryParams = queryString.stringify(
            Object.assign(
                {
                    queryAction: requestParam.queryAction,
                    querySearch: requestParam.querySearch,
                    pageIndex: pageNumber,
                    pageSize: requestParam.pageSize,
                },
                requestParam.filterItem
            )
        );
        return axios({
            method: "GET",
            url: `${promotionCnf.getAllCampaignUrl}?${queryParams}`,
            withCredentials: true,
        });
    },

    getFilterElementsListCampaign(pageNumber, requestParam) {
        let queryParams = queryString.stringify(
            Object.assign({
                queryAction: requestParam.queryAction,
                querySearch: requestParam.querySearch,
                pageIndex: pageNumber,
                pageSize: requestParam.pageSize,
            })
        );

        let filterItem = requestParam.filterItem;

        return axios({
            method: "POST",
            url: `${promotionCnf.filterCampaignUrl}?${queryParams}`,
            withCredentials: true,
            data: filterItem,
        });
    },
    getFilterElementsListCoupon(campaignCode, pageNumber, requestParam) {
        let queryParams = queryString.stringify(
            Object.assign({
                queryAction: requestParam.queryAction,
                querySearch: requestParam.querySearch,
                pageIndex: pageNumber,
                pageSize: requestParam.pageSize,
            })
        );

        let filterItem = requestParam.filterItem;
        return axios({
            method: "POST",
            url: `${promotionCnf.filterCouponUrl}/${campaignCode}?${queryParams}`,
            withCredentials: true,
            data: filterItem,
        });
    },

    getElementsListCoupon(campaignCode, pageNumber, requestParam) {
        let queryParams = queryString.stringify(
            Object.assign(
                {
                    queryAction: requestParam.queryAction,
                    querySearch: requestParam.querySearch,
                    pageIndex: pageNumber,
                    pageSize: requestParam.pageSize,
                    orderBy: requestParam.pagingItem.orderBy,
                    directionSort: requestParam.pagingItem.directionSort,
                },
                requestParam.filterItem
            )
        );
        return axios({
            method: "GET",
            url: `${promotionCnf.getAllCouponUrl}/${campaignCode}?${queryParams}`,
            withCredentials: true,
        });
    },

    deletePronotion(id) {
        return axios({
            method: "GET",
            url: promotionCnf.deletePromotionUrl.format(id),
            withCredentials: true,
        });
    },

    generateCoupon(generateInfo) {
        return axios({
            method: "POST",
            url: promotionCnf.generateCouponUrl,
            withCredentials: true,
            data: generateInfo,
        });
    },

    createCampaign(createForm) {
        return axios({
            method: "POST",
            url: promotionCnf.createCampaignUrl,
            withCredentials: true,
            data: createForm,
        });
    },

    editCampaign(editForm) {
        return axios({
            method: "POST",
            url: promotionCnf.editCampaignUrl,
            withCredentials: true,
            data: editForm,
        });
    },

    removeCampaign(id) {
        return axios({
            method: "GET",
            url: `${promotionCnf.removeCampaignUrl}/${id}`,
            withCredentials: true,
        });
    },

    deactiveCampaign(id) {
        return axios({
            method: "GET",
            url: `${promotionCnf.deactiveCampaignUrl}/${id}`,
            withCredentials: true,
        });
    },
    activeCampaign(id) {
        return axios({
            method: "GET",
            url: `${promotionCnf.activeCampaignUrl}/${id}`,
            withCredentials: true,
        });
    },

    updateNote(updateItem) {
        return axios({
            method: "POST",
            url: `${promotionCnf.updateNoteUrl}`,
            withCredentials: true,
            data: updateItem,
        });
    },
    updateAllowedBooting(updateItem) {
        return axios({
            method: "POST",
            url: `${promotionCnf.updateAllowedBooting}`,
            withCredentials: true,
            data: updateItem,
        });
    },
};
