import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from "@/components/SkeletonBox";
import promotionApi from "@/api/common/promotionNotification";
import { debounce } from "vue-debounce";
import { mapState } from "vuex";
import { isNumber } from "highcharts";
import moment from "moment";
export default {
    extends: baseComponent,
    data() {
        return {
            options: [
                {
                    value: 0,
                    label: "On running",
                },
                {
                    value: 1,
                    label: "Waiting",
                },
                {
                    value: 2,
                    label: "Expried",
                },
                {
                    value: 3,
                    label: "Inactive",
                },
            ],
            debounceFnc: null,
            debounceFilterFnc: null,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: { status: [] },
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 21,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "CREATED_AT",
                    directionSort: "desc",
                },
                data: [],
            },
            campaignSettingSelected: null,
            campaignDetailSelected: null,
            currentCommand: null,
            toogleFilter: false,
        };
    },
    computed: {
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 21,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "CREATED_AT",
                directionSort: "desc",
            };
        },
        defaultFilterItem() {
            return {
                status: [],
                pointBonus: 0,
            };
        },

        requestParam() {
            let filterItemTemp = {};
            if (
                this.elements.filterItem.status &&
                Array.isArray(this.elements.filterItem.status)
            ) {
                filterItemTemp = Object.assign(this.elements.filterItem, {
                    status: this.elements.filterItem.status.map((x) => parseInt(x)),
                });
            }

            let pointBonusTemp = 0;
            if (this.elements.filterItem.pointBonus !== "") {
                pointBonusTemp = parseInt(this.elements.filterItem.pointBonus);

                if (!isNumber(pointBonusTemp)) {
                    pointBonusTemp = 0;
                }
            }
            filterItemTemp.pointBonus = pointBonusTemp;
            return {
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: filterItemTemp,
            };
        },
        resultData: {
            set: function (val) {

                if (val) {
                    let temData = val.map((element) => {
                        element = Object.assign(element, {

                            startDate: moment(moment.utc(element.startDate).toDate()).local().format('YYYY-MM-DDTHH:mm'),
                            endDate: moment(moment.utc(element.endDate).toDate()).local().format('YYYY-MM-DDTHH:mm'),
                        });
                        return element;
                    });
                    this.elements.data = temData;
                }

            },
            get: function () {
                return null;
            },
        },
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            staffsInfo: (state) => state.staffs,
            lowBalanceUser: (state) => state.lowBalanceUser,
        }),
        rollDisable() {
            return !(this.rolesInfo.includes('QA') || this.rolesInfo.includes('OPERATOR_MANAGER '));
        },
    },
    created() {
        this.getElementsList(0);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        getElementsList(pageNumber) {

            this.showLoading();
            promotionApi
                .getElementsListCampaign(pageNumber, this.requestParam)
                .then((response) => {
                    if (
                        response.data &&
                        response.data.result === 0 &&
                        response.data.data !== null
                    ) {
                        this.resultData = response.data.data.data;
                        this.elements.pagingItem = response.data.data.pagingItem;
                        this.hideLoading();
                    } else {
                        this.elements.data = [];
                        this.elements.pagingItem = this.defaultPagingItem;
                        this.hideLoading();
                        if (
                            response.data.message !== null &&
                            response.data.message !== ""
                        ) {
                            commonAct.showError(
                                response.data.message || this.$lang.common.error
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    commonAct.showError(error);
                });
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        OnFilter(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = this.defaultFilterItem;
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.elements.querySearch = "";
            this.showLoading();
            this.getElementsList(1);
        },
        refreshData() {
            this.elements.queryAction = "";
            this.elements.querySearch = "";
            this.elements.filterItem = {};
            this.showLoading();
            this.getElementsList(1);
        },
        //Item action
        EditClick(item) {
            this.OpenSetting("edit", item);
        },
        AddClick() {
            this.OpenSetting("add", {});
        },
        DeActive(item) {
            commonAct.showConfirm(
                `<div class="text-left">DeActive this template?</div>`,
                () => {
                    this.showLoading();
                    promotionApi
                        .deactiveCampaign(item.id)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.getElementsList(this.elements.pagingItem.pageIndex);
                            } else {
                                this.hideLoading();
                                if (
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                }
            );
        },
        Active(item) {
            commonAct.showConfirm(
                `<div class="text-left">DeActive this template?</div>`,
                () => {
                    this.showLoading();
                    promotionApi
                        .activeCampaign(item.id)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.getElementsList(this.elements.pagingItem.pageIndex);
                            } else {
                                this.hideLoading();
                                if (
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                }
            );
        },
        viewClick(item) {
            this.openCouponManager(item);
        },
        //Modal action
        OpenSetting(commandItem, event) {
            var tempItem = Object.assign({}, event);
            if (tempItem && tempItem.startDate) {
                tempItem.startDate = new Date(tempItem.startDate);
            }
            else {
                tempItem.startDate = new Date(moment().startOf('day'));
                tempItem.campaignName = `Campaign ${moment().startOf('day').format('MM-YYYY')}`;
                tempItem.staffTeam = '';
                tempItem.partnerId = 0;
                tempItem.campaignType = 'POINT_BONUS';
                tempItem.maxApplyTimes = 1;
            }

            if (tempItem && tempItem.endDate) {
                tempItem.endDate = new Date(tempItem.endDate);
            }
            else {
                tempItem.endDate = new Date(moment().endOf('month').endOf('day'));
            }

            this.$set(this, "campaignSettingSelected", tempItem);
            this.$set(this, "currentCommand", commandItem);
            this.$nextTick(() => {
                $("#campainSettingModal").modal({ backdrop: "static", keyboard: true });
            });
        },
        closeSetting() {
            this.$set(this, "campaignSettingSelected", null);
            $("#campainSettingModal").modal("hide");
        },
        openCouponManager(event) {
            var tempItem = Object.assign({}, event);

            this.$set(this, "campaignDetailSelected", tempItem);
            this.$nextTick(() => {
                $("#couponManagerModal").modal({ backdrop: "static", keyboard: true });
            });
        },
        closeCouponManager() {
            this.$set(this, "campaignDetailSelected", null);
            $("#couponManagerModal").modal("hide");
        },

        //Handle save sucess
        onCreateSucceess() {
            this.closeSetting();
            this.getElementsList(this.elements.pagingItem.pageIndex);
        },
        onSaveSuccess() {
            this.closeSetting();
            this.getElementsList(this.elements.pagingItem.pageIndex);
        },
        //Handle paging click
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                default:
                    this.getElementsList(pageNumber);
                    break;
            }
        },
    },
    components: {
        CampainSetting: lazyLoadComponent({
            componentFactory: () => import("@/views/promotion/campainSetting"),
            loading: SkeletonBox,
        }),
        CouponManager: lazyLoadComponent({
            componentFactory: () => import("@/views/promotion/couponManager"),
            loading: SkeletonBox,
        }),
    },
};
