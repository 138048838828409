<template>
    <div>
        <div class="bg-white ml-1 mr-1" 
             style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2); border-radius: 5px !important;">
            <div class="p-0 pt-1 bg-white">
                <ul class="nav nav-tabs d-flex bd-highlight mr-3 ml-3 mt-2" id="custom-tabs-two-tab" role="tablist">
                    <li class="nav-item bd-highlight">
                        <a class="nav-link active"
                           data-toggle="pill" href="#coupon" role="tab"
                           aria-controls="custom-tabs-two-home" aria-selected="true">
                            <i class="el-icon-files"></i> Coupon manager
                        </a>
                    </li>
                    <li class="nav-item bd-highlight">
                        <a class="nav-link"
                           data-toggle="pill" href="#promotion" role="tab"
                           aria-controls="custom-tabs-two-home" aria-selected="true">
                            <i class="el-icon-present"></i> Promotion manager
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane fade show active" id="coupon" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                    <CampainList></CampainList>
                </div>
                <div class="tab-pane fade" id="promotion" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                    <PromotionList></PromotionList>
                </div>
            </div>
        </div>


        <!-- /.card -->
    </div>
</template>

<script>
    import promotApi from '@/api/common/promotionNotification';
    import promotionList from '@/views/promotion/promotionList';
    import campainList from '@/views/promotion/campainList';
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        data() {
            return {
                promotionForm: {
                    type: 0,
                    title: "",
                    body: "",
                    bodyData: "",
                    linkRedirec: ""
                },
                elementDetail: null,
                editorCommand: null
            }
        },
        methods: {
            saveChange() {
                this.promotionForm.bodyData = this.$refs.promotionContent.getContent();
                promotApi.sendPromotionNotification(this.promotionForm).then((res) => {

                }).catch((err) => {

                });
            }
        },
        components: {
            'PromotionList': promotionList,
            'CampainList': campainList,
        }
    }
</script>
<style>
    .pm-custom-select {
        padding: 0 0.3rem;
    }
</style>