import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import promotionApi from '@/api/common/promotionNotification';
import { debounce } from 'vue-debounce';
export default {
    extends: baseComponent,
    watch: {
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 21,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            elementDetail: null,
            editorCommand: null,
            settingCommand: null,
            editorData: '',
            editorConfig: {
                toolbar: "full"
            },
            visibleEditor: false
        };
    },
    computed: {
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 21,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: Object.assign(this.elements.filterItem)
            };
        }
    },
    created() {
        this.getElementsList(0);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    beforeDestroy() {

    },
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            promotionApi.getElementsListPromotion(pageNumber, this.requestParam).then(response => {


                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);
            });
        },
        getFilterQueryResult(pageNumber) {
        },
        getSearchQueryResult(pageNumber) {
        },
        
        refreshData() {
            this.elements.queryAction = "";
            this.elements.querySearch = "";
            this.elements.filterItem = {};
            this.getElementsList(1);
        },
        //Item action
        CoppyClick(item) {
            commonAct.showConfirm(`<div class="text-left">Coppy this template?</div>`, () => {
                this.showLoading();
                promotionApi.coppyPromotion(item).then((res) => {
                    if (res.data.result == 0) {
                        this.getElementsList(this.elements.pagingItem.pageIndex)
                        this.showSuccessToast(`<div class="text-left">Succees</div>`);
                    } else {
                        commonAct.showError(res.data.message);
                    }
                    this.hideLoading();
                }).catch((err) => {
                    commonAct.showError(err);
                    this.hideLoading();
                });
            });
        },
        EditClick(item) {
            this.OpenEditor("edit", item);
        },
        OpenSettingClick(item) {
            this.OpenSetting("editSetting", item);
        },
        AddClick() {
            this.OpenSetting("add");
        },
        RemoveClick(item) {
            commonAct.showConfirm(`<div class="text-left">Delete this template?</div>`, () => {
                this.showLoading();
                promotionApi.deletePronotion(item.id).then(response => {
                    if (response.data.result === 0) {
                        this.showSuccessToast(`<div class="text-left">Succees</div>`);
                        var index = this.elements.data.indexOf(item);
                        if (index > -1) this.elements.data.splice(index, 1);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },

        //Modal action
        OpenEditor(commandItem, event) {
            var tempItem = Object.assign({}, event);
            switch (commandItem) {
                case 'edit': {
                    this.$set(this, "elementDetail", tempItem);
                    this.$set(this, "editorCommand", commandItem);
                    break;
                }
                case 'add': {
                    this.$set(this, "elementDetail", event);
                    this.$set(this, "editorCommand", commandItem);
                    break;
                }
            }

            this.visibleEditor = true;
            // this.$nextTick(() => {
            //     $("#promotionEditorModal").modal({ backdrop: 'static', keyboard: true, });
            // });
        },
        closeEditor() {
            this.$set(this, "elementDetail", null);
            this.visibleEditor = false;
            //$("#promotionEditorModal").modal("hide");
        },
        OpenSetting(commandItem, event) {
            switch (commandItem) {
                case 'editSetting': {
                    var tempEditItem = Object.assign({}, event);
                    this.$set(this, "elementDetail", tempEditItem);
                    this.$set(this, "settingCommand", commandItem);
                    break;
                }
                case 'add': {
                    this.$set(this, "elementDetail", {});
                    this.$set(this, "settingCommand", commandItem);
                    break;
                }
            }
            this.$nextTick(() => {
                $("#promotionSettingModal").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeSetting() {
            $("#promotionSettingModal").modal("hide");
        },

        //Handle save sucess
        handleSaveSetting(event) {
            this.closeSetting();
            this.getElementsList(this.elements.pagingItem.pageIndex);
            switch (this.settingCommand) {
                case "add": {
                    this.OpenEditor("add", event);
                    break;
                }
                case "edit": {

                    break;
                }
            }
        },
        handleSaveEditor(event) {
            this.closeEditor();
            this.getElementsList(this.elements.pagingItem.pageIndex)
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getElementsList(pageNumber);
                    break;
            }
        },
    },
    components: {
        PromotionEditor: lazyLoadComponent({
            componentFactory: () => import("@/views/promotion/promotionEditor"),
            loading: SkeletonBox,
        }),
        PromotionSetting: lazyLoadComponent({
            componentFactory: () => import("@/views/promotion/SettingPromotion"),
            loading: SkeletonBox,
        })
    }
};

